<template>
	<!-- 开票历史 -->
	<div class="invoiceTitle">
		<van-nav-bar title="开票历史" left-arrow @click-left="$router.go(-1)" />
		<van-checkbox-group  @change="getApplyList" v-model="checked" >
		<van-tabs class="tabs" @change="changeActive" v-model:active="active">
			<van-tab name="0" title="全部">
				<div class="list">
					<div class="item" v-for="item in list" >
						<div class="item-val">
							<div>
								<span>{{item.startConsultTime}} </span>
								<span class="item-val-name"> {{item.chatRoomName}}</span>
							</div>
							<div>
								<span>医生： {{item.doctorName}}（{{item.serverOrganization}}） </span>
							</div>
							<div class="money-view" >
								<span class="money" >{{getServerPrice(item.serverPrice)}}元</span>
							</div>
						</div>
						<div class="buttons" >
							<div>
							<!-- {{item.invoiceStatus==1?"已开票":"开票中"}} -->
							<span v-if="item.invoiceStatus == 0">开票中</span>
							<span v-if="item.invoiceStatus == 1">已开票</span>
							<span v-if="item.invoiceStatus == 2">开票中</span>
							<span v-if="item.invoiceStatus == 3">开票中</span>
							<span v-if="item.invoiceStatus == 4">已作废</span>
							</div>
							<!-- <div>
								<van-button plain size="small">重发发票</van-button>
								<van-button style="margin-left: 10px;" plain size="small">申请重开</van-button>
							</div> -->
						</div>
					</div>
				</div>
			</van-tab>
			<van-tab name="1" title="图文会诊">
				<div class="list">
					<div class="item" v-for="item in list" >
						<div class="item-val">
							<div>
								<span>{{item.startConsultTime}} </span>
								<span class="item-val-name"> {{item.chatRoomName}}</span>
							</div>
							<div>
								<span>医生： {{item.doctorName}}（{{item.serverOrganization}}） </span>
							</div>
							<div class="money-view" >
								<span class="money" >{{getServerPrice(item.serverPrice)}}元</span>
							</div>
						</div>
						<div class="buttons" >
							<div>
								<span v-if="item.invoiceStatus == 0">开票中</span>
								<span v-if="item.invoiceStatus == 1">已开票</span>
								<span v-if="item.invoiceStatus == 2">开票中</span>
								<span v-if="item.invoiceStatus == 3">开票中</span>
								<span v-if="item.invoiceStatus == 4">已作废</span>
							</div>
							<!-- <div>
								<van-button plain size="small">重发发票</van-button>
								<van-button style="margin-left: 10px;" plain size="small">申请重开</van-button>
							</div> -->
						</div>
					</div>
				</div>
			</van-tab>
			<van-tab name="2" title="语音会诊"><div class="list">
					<div class="item" v-for="item in list" >
						<div class="item-val">
							<div>
								<span>{{item.startConsultTime}} </span>
								<span class="item-val-name"> {{item.chatRoomName}}</span>
							</div>
							<div>
								<span>医生： {{item.doctorName}}（{{item.serverOrganization}}） </span>
							</div>
							<div class="money-view" >
								<span class="money" >{{getServerPrice(item.serverPrice)}}元</span>
							</div>
						</div>
						<div class="buttons" >
							<div>
								<span v-if="item.invoiceStatus == 0">开票中</span>
								<span v-if="item.invoiceStatus == 1">已开票</span>
								<span v-if="item.invoiceStatus == 2">开票中</span>
								<span v-if="item.invoiceStatus == 3">开票中</span>
								<span v-if="item.invoiceStatus == 4">已作废</span>
							</div>
						<!-- 	<div>
								<van-button plain size="small">重发发票</van-button>
								<van-button style="margin-left: 10px;" plain size="small">申请重开</van-button>
							</div> -->
						</div>
					</div>
				</div></van-tab>
			<van-tab name="3" title="视频会诊"><div class="list">
					<div class="item" v-for="item in list" >
						<div class="item-val">
							<div>
								<span>{{item.startConsultTime}} </span>
								<span class="item-val-name"> {{item.chatRoomName}}</span>
							</div>
							<div>
								<span>医生： {{item.doctorName}}（{{item.serverOrganization}}） </span>
							</div>
							<div class="money-view" >
								<span class="money" >{{getServerPrice(item.serverPrice)}}元</span>
							</div>
						</div>
						<div class="buttons" >
							<div>
								<span v-if="item.invoiceStatus == 0">开票中</span>
								<span v-if="item.invoiceStatus == 1">已开票</span>
								<span v-if="item.invoiceStatus == 2">开票中</span>
								<span v-if="item.invoiceStatus == 3">开票中</span>
								<span v-if="item.invoiceStatus == 4">已作废</span>
							</div>
							<!-- <div>
								<van-button plain size="small">重发发票</van-button>
								<van-button style="margin-left: 10px;" plain size="small">申请重开</van-button>
							</div> -->
						</div>
					</div>
				</div></van-tab>
		</van-tabs>
		</van-checkbox-group>
		<!-- <div class="bottomView" >
			<div class="total">
				{{checked.length}}个订单，共{{totalMoney}}元
			</div>
			<van-button size="small" type="primary" @click="showApplyInvoiceSend" :disabled="checked.length==0" >确认开票</van-button>
		</div> -->
		<!-- 开票弹框 -->
		<van-popup v-model:show="isShow.applyInvoiceSend" teleport="body" position="right" style="width: 100%;height: 100%;" >
			<div style="width: 100%;height: 100%;overflow: auto;">
				<apply-invoice-send v-model:show="isShow.applyInvoiceSend" :checkedObj="checkedObj" ></apply-invoice-send>
			</div>
		</van-popup>

	</div>
</template>

<script>
	import {
		NavBar,
		Tabs,
		Tab,
		Checkbox,
		CheckboxGroup,
		Button,
		Popup,
	} from "vant";
	import applyInvoiceSend from "./applyInvoiceSend.vue"
	export default {
		name: "invoiceHistory",
		components: {
			[NavBar.name]: NavBar,
			[Tabs.name]: Tabs,
			[Tab.name]: Tab,
			[Checkbox.name]:Checkbox,
			[CheckboxGroup.name]:CheckboxGroup,
			[Button.name]:Button,
			[Popup.name]:Popup,
			applyInvoiceSend
		},
		provide() {
			return {};
		},
		data() {
			return {
				active: "0",
				list: [],
				checked:[],	
				totalMoney:0,
				isShow:{},
				checkedObj:{
					list:[],
				},
			};
		},
		mixins: [],
		watch: {},
		created() {
			this.getList()
		},
		mounted() {},
		methods: {
			// 获取列表
			getList(){
				this.list=[];
				this.$http.request("invoiceHistory",{
					 patientId: this.$cache.local.getItem("patientId"),
					serverType:this.active=='0'?undefined:this.active,
				})
				.then((res)=>{
					this.list=res
				})
			},
			// 返回价格
			getServerPrice(n){
			if(!n){
				return n
			}
			n=n/100;
			return Number(n.toFixed(2))
			},
			// 更改标签
			changeActive(){
				this.checked=[];
				this.getList();
				this.getApplyList()
			},
			// 复选状态更改
			getApplyList(){
				console.log(("=============="))
				// 测试，--id
				if(this.checked.length<=0){
					this.totalMoney=0
				}
				let s=0;
				for(let i in this.list){
					if(this.checked.indexOf(this.list[i].id)!=-1){
						s+=this.list[i].id
					}
				}
				this.totalMoney=s;
			},
			// 显示开票
			showApplyInvoiceSend(){
				// 获取选中的数据
				let list=[];
				let s=0;
				for(let i in this.list){
					if(this.checked.indexOf(this.list[i].id)!=-1){
						list.push(this.list[i])
						s+=this.list[i].id
					}
				}
				this.checkedObj={
					money:s,
					list:list,
				}
				this.isShow.applyInvoiceSend=true;
			}
		},
		beforeUnmount() {},
		// beforeRouteLeave(to,from,next){},
	};
</script>
<style scoped lang="scss">
	.invoiceTitle {
		height: 100vh;
	}
	.tabs{
		height: calc(100vh - 50px);
		overflow: hidden;
	}
	.list {
		box-sizing: border-box;
		margin: 10px 0;
		height: calc(100vh - 100px);
		overflow: auto;
		.item{
			padding: 10px;
			border-bottom: 1px solid #ccc;
			font-size: 14px;
			line-height: 28px;
			.money-view{
				text-align: right;
				.money{
					font-weight: 600;
					color: #00a8ff;
				}
			}
			.item-val{}
			.item-val-name{
				margin-left: 6px;
				font-weight: 600;
			}
		}
		.buttons{
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
</style>